import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EvgApiService } from 'src/app/services/evg-api.service';
import { ResponseHandlerService } from 'src/app/services/response-handler.service';
import { SharedUtilService } from 'src/app/services/shared-util.service';
import { ShareinfoService } from 'src/app/services/shareinfo.service';
import { TranslateConfigService } from 'src/app/services/translate-config.service';
import { environment } from 'src/environments/environment';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ImageSnippet } from 'src/app/modules/common/ImageSnippet';
import { User } from 'src/app/modules/common/User';
import { ImageListComponent } from 'src/app/modules/image-list/image-list.component';
import { Products } from 'src/app/modules/common/Products';
import { Clipboard, ClipboardModule } from '@angular/cdk/clipboard';
import { ViewProductsComponent } from 'src/app/modules/views/view-products/view-products.component';
import { ViewAirPuifierComponent } from 'src/app/modules/views/view-air-puifier/view-air-puifier.component';
import { ViewWasherComponent } from 'src/app/modules/views/view-washer/view-washer.component';
import { ViewFulvicAcidComponent } from 'src/app/modules/views/view-fulvic-acid/view-fulvic-acid.component';
import { ViewHydrogenEnergyComponent } from 'src/app/modules/views/view-hydrogen-energy/view-hydrogen-energy.component';
import { ViewPetC60Component } from 'src/app/modules/views/view-pet-c60/view-pet-c60.component';
import { ViewCarbon60Component } from 'src/app/modules/views/view-carbon60/view-carbon60.component';
import { ViewPetTraceComponent } from 'src/app/modules/views/view-pet-trace/view-pet-trace.component';
import { ViewFirmingCreamComponent } from 'src/app/modules/views/view-firming-cream/view-firming-cream.component';
import { ViewHydratingSerumComponent } from 'src/app/modules/views/view-hydrating-serum/view-hydrating-serum.component';
import { ViewNightCreamComponent } from 'src/app/modules/views/view-night-cream/view-night-cream.component';
import { ViewTintedPoreComponent } from 'src/app/modules/views/view-tinted-pore/view-tinted-pore.component';
import { ViewWrinkleSerumComponent } from 'src/app/modules/views/view-wrinkle-serum/view-wrinkle-serum.component';
import { ViewRestorativeComponent } from 'src/app/modules/views/view-restorative/view-restorative.component';

@Component({
  selector: 'app-my-shopping-store',
  templateUrl: './my-shopping-store.component.html',
  styleUrls: ['./my-shopping-store.component.scss'],
})
export class MyShoppingStoreComponent implements OnInit {
  public myStoreInfo: any={};
  public myCartForm: FormGroup;
  private _unsubscribe$ = new Subject<any>();
  public user;
  public link = this.translate.translate.instant('MYSTORELINK');//"Fill up form to build your personal webpage link.";
  public selectedFile: ImageSnippet=null;
  public upload_file_name=null;
  public currentRate:number=0;
  public products: Products[] = [];
  public currentLink = null;

  constructor(
    private router : Router,
    public translate : TranslateConfigService, 
    private evgService : EvgApiService,
    public shareinfoService : ShareinfoService,
    private responseHandlerService : ResponseHandlerService,
    private sharedUtilService : SharedUtilService,
    private formBuilder : FormBuilder,
    private cdref : ChangeDetectorRef,
    private ngDialog : NgbModal,
    private clipboard: Clipboard,
    //private clipboardModule : ClipboardModule,
  ) { }

  ngOnInit(): void {
    //console.log(this.router.url);
    this.getMySoreInfo();

    this.getUser();
    this.initForms();
    this.getLink();
    this.getProducts();
  }

  initForms()
  {
    this.myCartForm = this.formBuilder.group({
      id:[],
      cart_name  : ['', [Validators.required]],
      cart_adv  : [],
      cart_note  : [],
      cart_backgroud  : [],
      show_img_type  : [0,[]],
      pic_path  : []
    });

    this.myCartForm.valueChanges.subscribe(selectedValue  => {
      this.getLink();
      this.cdref.detectChanges();
      console.log("CHANGED");
    })
  }

  getUser()
  {
    this.shareinfoService.user
    .pipe( takeUntil(this._unsubscribe$) )
        .subscribe(
          (successResponse: any) => {
            this.user = successResponse ? successResponse : new User();
            //console.log('getUser successResponse:',successResponse);
          },
          (errorResult) => {
            console.log("System Error", errorResult);
            this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
          }
    );
  }

  getLink()
  {
    this.link = environment.shoppingCart + "/byteCart/" + this.user.id;
    return this.link;
  }

  getProducts()
  {
    this.evgService.getProductsForshoppingCart({})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          if(resp.data[0]){
            this.products = resp.data[0].results;
            this.products.forEach(async (element,index) => {
                this.products[index].pQty = 1;
            });
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.responseHandlerService.handleCustomResponse('Link copied to clipboard!','success');

  }

  saveMyStore(){
    if(this.myCartForm.status == "VALID"){
      let data = this.myCartForm.value;
      if ( (!(data.cart_backgroud) || (data.cart_backgroud).length==0) || (data.cart_backgroud && data.cart_backgroud.length>0 && (/^#([0-9A-F]{3}){1,2}$/i.test(data.cart_backgroud)))){
        this.evgService.postByteCart(data)
        .pipe( takeUntil(this._unsubscribe$) )
        .subscribe((resp) => {
          if (resp) {
            this.responseHandlerService.handleResponse(resp);
          }
        },(errorResult) => {
          console.log("System Error", errorResult);
          this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
        });
      } else {
        alert('Please enter correct color code of background !');
      }
    }
  }

  getMySoreInfo() {
    this.evgService.getByteCart({})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        //console.log("getMySoreInfo  resp:", resp);
        if (resp) {
          if(resp.data[0]){
            let myStoreInfo = resp.data[0].results;
            if (! (Object.keys(myStoreInfo).length === 0)) {
                this.myCartForm.patchValue({
                  id : myStoreInfo.id,
                  cart_name : myStoreInfo.cart_name,
                  cart_adv : myStoreInfo.cart_adv,
                  cart_note : myStoreInfo.cart_note,
                  cart_backgroud : myStoreInfo.cart_backgroud,
                  show_img_type : myStoreInfo.show_img_type,
              });
              this.myStoreInfo = myStoreInfo;
            }
            //console.log("getMySoreInfo  resp:", resp);
          }
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
    /*
    this.evgService.getMyCartInfo({MemberNo : this.recommanded}).subscribe( resp => {
      console.log("getMySoreInfo  resp:", resp);
      if (resp) {
        this.myStoreInfo = resp.data[0];
      }
    },(errorResult) => {
      console.log("System Error", errorResult);
      this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
    });
    */

  }

  uploadImageFile(){
    let imageAsData = this.selectedFile.src;
    let imageFile = this.selectedFile.file.name;
    this.evgService.uploadMyShoppingImages({image: imageAsData, fileName: imageFile})
      .pipe( takeUntil(this._unsubscribe$) )
      .subscribe((resp) => {
        if (resp) {
          this.responseHandlerService.handleResponse(resp);
          this.selectedFile=null;
        }
      },(errorResult) => {
        console.log("System Error", errorResult);
        this.responseHandlerService.handleCustomResponse("System Error Occured", "error");
      });
  }

  uploadImgFile(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      this.selectedFile = new ImageSnippet(event.target.result, file);
      //console.log('uploadImgFile this.selectedFile:',this.selectedFile);
      //console.log('uploadImgFile this.file:',file);
      this.cdref.detectChanges();
    });
    reader.readAsDataURL(file);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const height = img.naturalHeight;
        const width = img.naturalWidth;
        //console.log('Width and Height', width, height);
        let rate = height/width ;
        this.currentRate= Math.round(width/height*100)/100;
        if (rate< 0.18 || rate> 0.22) {
          alert("Please upload a correct image file size (Height:Width => around 1:5),\n current :"+height + 'px:'+width+"px => 1:" + this.currentRate);
        }
      };
    };
  }

  openImageListBox() {
    const modalRef = this.ngDialog.open(ImageListComponent, { windowClass: 'confirm-dialog-modal' ,centered: true, size: this.shareinfoService.getPopupSize(), keyboard: false, backdrop: "static" });
    modalRef.componentInstance.translate = this.translate;
    modalRef.componentInstance.memberNo = this.user.id;
    modalRef.componentInstance.message = this.translate.translate.instant('DELETECONFIRM') ;
    modalRef.result.then((result) => {
      if(result) {
      }
    });
  }

  copyOfferedLink(docObj : any) {
    if (docObj && docObj.pId) {
      let link = environment.shoppingCart + '/byteCart/'+ this.user.id + '/' + docObj.pId;
      this.currentLink = link;
      //this.clipboard.copy(link);
      this.responseHandlerService.handleResponse({success:true, message: 'done !'});
      return link;
    }
  }

  copyAchievements(pId) {
    const pending = this.clipboard.beginCopy(environment.shoppingCart + '/byteCart/'+ this.user.id + '/' + pId);
    let remainingAttempts = 10;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        // Remember to destroy when you're done!
        pending.destroy();
        if (result) {
          this.responseHandlerService.handleResponse({success:true, message: 'done !'});
        }
      }
    };
    attempt();
  }

  viewProductDetail(page_name) {
    let pagesList=[
      {pagename: "fulvic-acid", pageComponent: ViewFulvicAcidComponent },
      {pagename: "hydrogen-energy", pageComponent: ViewHydrogenEnergyComponent },
      {pagename: "products", pageComponent: ViewProductsComponent },
      //{pagename: "washer", pageComponent: ViewWasherComponent },
      {pagename: "air-purifier", pageComponent: ViewAirPuifierComponent },
      {pagename: "pet-c60", pageComponent: ViewPetC60Component},
      {pagename: "carbon60", pageComponent: ViewCarbon60Component},
      {pagename: 'pet-trace', pageComponent: ViewPetTraceComponent},
      {pagename: 'firming-cream', pageComponent: ViewFirmingCreamComponent},
      {pagename: 'hydrating-serum', pageComponent: ViewHydratingSerumComponent},
      {pagename: 'night-cream', pageComponent: ViewNightCreamComponent},
      {pagename: 'tinted-pore', pageComponent: ViewTintedPoreComponent},
      {pagename: 'wrinkle-serum', pageComponent: ViewWrinkleSerumComponent},
      {pagename: 'restorative', pageComponent: ViewRestorativeComponent},

    ];

   let pageNames = page_name ? page_name.split(';') : null;
   console.log('viewProductDetail pageNames:',pageNames);
   if (pageNames && pageNames.length>0) {
    pageNames.forEach((value, index) =>{
      if (value ) {
        let findPage = pagesList.find(item=> item.pagename==value);
        if (findPage) {
          const modalRef = this.ngDialog.open(findPage.pageComponent, { windowClass: 'cart-modal' ,centered: true, size: 'lg', keyboard: false, backdrop: true });
          modalRef.componentInstance.translate = this.translate;
        }
      }
    })
   }
  }
}
