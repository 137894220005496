<div class="personal-webpage-container">
    
    <div class="row gutters-sm">
      
      <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-header">
                
            </div>
            <div class="card-body">
                <form class="formStyle" #personalWebpage="ngForm" [formGroup]="personalWebpageForm" (keydown.enter)="$event.preventDefault()" autocomplete="ps-form">
                      
                    <!-- <div class="row">
                         d-flex justify-content-center
                        <div class="col-sm-12">
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="amount"  type="number" class="form-control text-box" id="amount" 
                                name="amount" placeholder="{{ 'AMOUNTUSD' | translate }}"
                                autocomplete="off"
                                >
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('amount').hasError('required')">{{'AMOUNTREQ' | translate}}</mat-error>
                            </mat-form-field>
                        </div>
                    </div> -->
                    <!--<div class="row mt-5" style="max-width: 700px;">-->
                    <div class="row mt-5 enterSection">
                        <div class="col-sm-12 mb-3">
                            <h3 translate ngbAutofocus>PERSONALINFO</h3>
                        </div>
                        <div class="col-sm-6">
                            <mat-form-field style="width: 100%;"  appearance="none">
                                <label for="displayName" class="mb-2" translate>DISPLAYNAME</label>
                                <input matInput formControlName="displayName" type="text" class="form-control" id="displayName" aria-describedby="displayName"
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="personalWebpageForm.get('displayName').hasError('required')" >{{ "DISPLAYNAMEREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <label for="email" class="mb-2" translate>EMAIL</label>
                                <input matInput formControlName="email" type="text" class="form-control" id="email" aria-describedby="email"
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="personalWebpageForm.get('email').hasError('required')" >{{ "EMAILREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;" appearance="none">
                                <label for="contactNumber" class="mb-2" translate>CONTACTNUMBER</label>
                                <input matInput formControlName="contactNumber" type="text" class="form-control" id="contactNumber" aria-describedby="contactNumber"
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="personalWebpageForm.get('contactNumber').hasError('required')" >{{ "CONTACTNUMBERREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <!-- <mat-form-field style="width: 100%;">
                                <mat-label>{{'CARDTYPE' | translate}}</mat-label>
                                <mat-select formControlName="cardType"  type="text" class="form-control" id="cardType" aria-describedby="autoPay"
                                autocomplete="off">
                                    <mat-option  [value]="0">{{ 'VISA' | translate }}</mat-option>
                                    <mat-option  [value]="1">{{ 'MASTERCARD' | translate }}</mat-option>
                                    <mat-option  [value]="2">{{ 'AMERICANEXPRESS' | translate }}</mat-option>
                                </mat-select>
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cardType').hasError('required')">{{'CARDTYPEREQ' | translate}}</mat-error>
                            </mat-form-field> -->
                            <!-- <mat-form-field style="width: 100%;">
                                <input matInput formControlName="firstName" type="text" class="form-control" id="firstName" aria-describedby="firstName"
                                placeholder="{{ 'FIRSTNAME' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('firstName').hasError('required')" >{{ "FIRSTNAMEREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="lastName" type="text" class="form-control" id="lastName" aria-describedby="lastName"
                                placeholder="{{ 'LASTNAME' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('lastName').hasError('required')" >{{ "LASTNAMEREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="postCode" type="text" class="form-control" id="postCode" aria-describedby="postCode"
                                placeholder="{{ 'POSTALCODELABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('postCode').hasError('required')" >{{ "POSTALCODEREQ" | translate }} </mat-error>
                            </mat-form-field> <mat-form-field style="width: 100%;">
                                <input matInput formControlName="address" type="text" class="form-control" id="address" aria-describedby="address"
                                placeholder="{{ 'ADDRESSLABEL' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('address').hasError('required')" >{{ "ADDRESSREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <mat-form-field style="width: 100%;">
                                <input matInput formControlName="cardNo" type="number" class="form-control" id="cardNo" aria-describedby="cardNo"
                                placeholder="{{ 'CCNUMBER' | translate }}" 
                                autocomplete="off">
                                <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cardNo').hasError('required')" >{{ "CCNUMBERREQ" | translate }} </mat-error>
                            </mat-form-field>
                            <div class="row">
                                <div class="col-sm-7">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="expireDate" type="month" pattern="[0-9]{4}-[0-9]{2}" class="form-control" id="expireDate" aria-describedby="expireDate"
                                        placeholder="{{ 'CCEXP' | translate }}" 
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="purchaseTokenForm.get('expireDate').hasError('required')" >{{ "CCEXPREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-5">
                                    <mat-form-field style="width: 100%;">
                                        <input matInput formControlName="cvCode" type="number" class="form-control" id="cvCode" aria-describedby="cvCode"
                                        placeholder="{{ 'CCCVC' | translate }}" 
                                        autocomplete="off">
                                        <mat-error class="error-message" *ngIf="purchaseTokenForm.get('cvCode').hasError('required')" >{{ "CCCVCREQ" | translate }} </mat-error>
                                    </mat-form-field>
                                </div>
                            </div> -->
                        </div>
                        <div class="col-sm-6" id="rightSection">
                            <mat-form-field style="width: 100%;">
                                <label for="linkType" class="mb-2" translate>SELECTLINKTYPE</label>
                                <mat-select formControlName="linkType"  type="text" class="form-control" id="linkType" aria-describedby="autoPay"
                                            autocomplete="off">
                                    <mat-option class="matOption" value="/home">{{ 'HOMEPAGE' | translate }}</mat-option>
                                    <mat-option class="matOption" value="/hydrogen-energy">{{ 'HENERGYTITLE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/fulvic-acid">{{ 'FULVICTITLE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption"  value="/carbon60">{{ 'CARBON60PAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/pet-c60">{{'PETPRODUCTS' | translate}}&nbsp;/&nbsp;{{ 'PETC60PAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/pet-trace">{{'PETPRODUCTS' | translate}}&nbsp;/&nbsp;{{ 'PETTRACEELEPAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/hydrating-serum">{{'SKINCARE' | translate}}&nbsp;/&nbsp;{{ 'HYDRSERUMPAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/wrinkle-serum">{{'SKINCARE' | translate}}&nbsp;/&nbsp;{{ 'WRINKLESERUMPAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/firming-cream">{{'SKINCARE' | translate}}&nbsp;/&nbsp;{{ 'FIRMINGCREAMPAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/night-cream">{{'SKINCARE' | translate}}&nbsp;/&nbsp;{{ 'NIGHTCREAMPAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption" value="/tinted-pore">{{'SKINCARE' | translate}}&nbsp;/&nbsp;{{ 'TINTEDPOREPAGE' | translate }}&nbsp;{{ 'PAGE' | translate}}</mat-option>
                                    <mat-option class="matOption"  value="/products">{{ 'UVALKALINEPAGE' | translate }}</mat-option>
                                    <mat-option class="matOption" value="/videos/1">{{ 'VIDEO1' | translate }}</mat-option>
                                    <mat-option class="matOption" value="/videos/2">{{ 'VIDEO2' | translate }}</mat-option>
                                    <mat-option class="matOption" value="/videos/3">{{ 'VIDEO3' | translate }}</mat-option>
                                    <mat-option class="matOption" value="/videos/4">{{ 'VIDEO4' | translate }}</mat-option>
                                    <mat-option class="matOption" value="/air-purifier">{{ 'AIRPURIFIERPAGE' | translate }}</mat-option>
                                    <!--<mat-option class="matOption" value="/washer">{{ 'WASHERPAGE' | translate }}</mat-option>-->
                                </mat-select>
                                <mat-error class="error-message" *ngIf="personalWebpageForm.get('linkType').hasError('required')">{{'LINKTYPEREQ' | translate}}</mat-error>
                            </mat-form-field> 
                            <label class="mb-2" translate>LINK</label>
                            <div class="link-box" >{{ link }}</div>
                            <div class="mt-2 copyButton">
                                <button [disabled]="!personalWebpageForm.valid" class="btn orange-button" (click)="copyMessage(link)">{{ 'COPYLINK' | translate}}</button>
                            </div>
                            <div class="link-notice">{{ 'LINKNOTICE' | translate }}<a href="https://free-url-shortener.rb.gy/" target="_blank">https://free-url-shortener.rb.gy/</a>

                            </div>
                        </div>
                    </div>

                
                    <!-- <div class="col-sm-12 col-xs-12 form-popup-form">
                        <div style="text-align: center; margin: auto;">
                            <button class="btn orange-button" [disabled]="!purchaseTokenForm.valid " (click)="purchaseToken(purchaseTokenF)" >{{ "PURCHASE" | translate}}</button>
                        </div>
                    </div>
                     -->
                  
                  </form>

            </div>
          </div>
        </div>
</div>