import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthchecService } from './services/authchec.service';
import { ByteCartComponent } from './components/pages/byte-cart/byte-cart.component';
import { ByteShopComponent } from './components/pages/byte-shop/byte-shop.component';
import { LoginComponent } from './modules/login/login.component';
//{ path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), canActivate: [AuthchecService] },

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule),  },
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule),  },
  { path: 'products', loadChildren: () => import('./components/pages/products/products.module').then(m => m.ProductsModule), },
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), },
  { path: 'news', loadChildren: () => import('./components/pages/news/news.module').then(m => m.NewsModule), },
  { path: 'donate', loadChildren: () => import('./components/pages/donate/donate.module').then(m => m.DonateModule), },
  { path: 'backmain', loadChildren: () => import('./components/bpages/main/main.module').then(m => m.MainModule), canActivate: [AuthchecService]},
  { path: 'water-truth', loadChildren: () => import('./components/pages/water-truth/water-truth.module').then(m => m.WaterTruthModule) },
  { path: 'water-analysis', loadChildren: () => import('./components/pages/water-analysis/water-analysis.module').then(m => m.WaterAnalysisModule) },
  { path: 'pet-c60', loadChildren: () => import('./components/pages/pet-c60/pet-c60.module').then(m => m.PetC60Module) },
  { path: 'pet-trace', loadChildren: () => import('./components/pages/pet-trace/pet-trace.module').then(m => m.PetTraceModule) },
  { path: 'carbon60', loadChildren: () => import('./components/pages/carbon60/carbon60.module').then(m => m.Carbon60Module) },
  { path: 'hydrogen-energy', loadChildren: () => import('./components/pages/hydrogen-energy/hydrogen-energy.module').then(m => m.HydrogenEnergyModule) },
  { path: 'fulvic-acid', loadChildren: () => import('./components/pages/fulvic-acid/fulvic-acid/fulvic-acid.module').then(m => m.FulvicAcidModule) },
  { path: 'fulvic-mucuna-pruriens', loadChildren: () => import('./components/pages/fulvic-acid/fulvic-mucuna-pruriens/fulvic-mucuna-pruriens.module').then(m => m.FulvicMucunaPruriensModule) },
  { path: 'hydrating-serum', loadChildren: () => import('./components/pages/skin-care/hydrating-serum/hydrating-serum.module').then(m => m.HydratingSerumModule) },
  { path: 'firming-cream', loadChildren: () => import('./components/pages/skin-care/firming-cream/firming-cream.module').then(m => m.FirmingCreamModule) },
  { path: 'wrinkle-serum', loadChildren: () => import('./components/pages/skin-care/wrinkle-serum/wrinkle-serum.module').then(m => m.WrinkleSerumModule) },
  { path: 'night-cream', loadChildren: () => import('./components/pages/skin-care/night-cream/night-cream.module').then(m => m.NightCreamModule) },
  { path: 'tinted-pore', loadChildren: () => import('./components/pages/skin-care/tinted-pore/tinted-pore.module').then(m => m.TintedPoreModule) },
  { path: 'restorative', loadChildren: () => import('./components/pages/skin-care/restorative/restorative.module').then(m => m.RestorativeModule) },
  { path: 'testimonials', loadChildren: () => import('./components/pages/testimonials/testimonials.module').then(m => m.TestimonialsModule) },
  // { path: 'warranty', loadChildren: () => import('./components/pages/warranty/warranty.module').then(m => m.WarrantyModule) },
  // { path: 'whole-house-system', loadChildren: () => import('./components/pages/whole-house-system/whole-house-system.module').then(m => m.WholeHouseSystemModule) },
  { path: 'privacy-policy', loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
  //{ path: 'washer', loadChildren: () => import('./components/pages/washer/washer.module').then(m => m.WasherModule) },
  { path: 'ultimate-ro-system', loadChildren: () => import('./components/pages/ultimate-ro-system/ultiRoSys.module').then(m => m.UltiRoSysModule) },
  { path: 'air-purifier', loadChildren: () => import('./components/pages/air-purifier/air-purifier.module').then(m => m.AirPurifierModule) },
//  { path: 'blog', loadChildren: () => import('./components/pages/blog-posts/blog-posts.module').then(m => m.BlogPostsModule) },
//  { path: 'blog/:id', loadChildren: () => import('./components/pages/blog-posts/blog-posts.module').then(m => m.BlogPostsModule) },
  { path: 'blog', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule) },
  { path: 'blog/:id', loadChildren: () => import('./components/pages/blog/blog.module').then(m => m.BlogModule) },
  { path: 'videos', loadChildren: () => import('./components/pages/videos/videos.module').then(m => m.VideosModule) },
  { path: 'videos/:id', loadChildren: () => import('./components/pages/videos/videos.module').then(m => m.VideosModule) },
//  { path: 'byteCart', component: ByteCartComponent },
//  { path: 'byteCart/:recommanded', component: ByteCartComponent },
{ path: 'byteCart',  loadChildren: () => import('./components/pages/byte-cart/byte-cart.module').then(m => m.ByteCartModule) },
{ path: 'byteCart/:recommanded/:pId', loadChildren: () => import('./components/pages/byte-cart/byte-cart.module').then(m => m.ByteCartModule) },
{ path: 'byteCart/:recommanded', loadChildren: () => import('./components/pages/byte-cart/byte-cart.module').then(m => m.ByteCartModule) },
//{ path: 'byteCart/:userId', loadChildren: () => import('./components/pages/byte-cart/byte-cart.module').then(m => m.ByteCartModule) },
  //{ path: 'payment', loadChildren: () => import('./modules/online-payment/online-payment.module').then(m => m.PaymentModule) }
{ path: 'payment/:access_token', loadChildren: () => import('./components/pages/online-payment/online-payment.module').then(m => m.OnlinePaymentModule) },
{ path: 'download-centre', loadChildren: () => import('./components/pages/download-centre/download-centre.module').then(m => m.DownloadCentreModule) },
{ path: 'byte-shop', component: ByteShopComponent},
{ path: 'login', component: LoginComponent},
{ path: 'washer', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule),  },
];
/*
import { OnlinePaymentComponent } from 'src/app/modules/online-payment/online-payment.component';

const modalRef = this.ngDialog.open(OnlinePaymentComponent, { windowClass: 'cart-modal' ,centered: true, size: 'gl', keyboard: false, backdrop: "static" });
modalRef.componentInstance.translate = this.translate;
*/

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'legacy'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
